(function() {
	var items = document.querySelectorAll('.md__form');

	items.forEach(el => {
		MdForm.call(el);
	});

	function MdForm () {
		var $self = this;
		var input = this.querySelector('.md__input');

		input.addEventListener('focus', function() {
			$self.classList.add('focus');
		});

		input.addEventListener('blur',function() {
			if(input.value === '') {
				$self.classList.remove('focus');
			}
		});
	}

})();
