(function () {
	if(window.customElements) {
		class LazyImage extends HTMLElement{
			constructor(data) {
				super(data);
			}

			connectedCallback() {
				const self = this;
				const observer = new IntersectionObserver(function (entries) {
					for(var i =0; i<entries.length; i++) {
						var entry = entries[i];

						if(entry.isIntersecting) {
							let img   = document.createElement('img');
							let attrs = ['width', 'height', 'src', 'alt', 'srcset', 'sizes'];

							self.image = img;

							self.innerHTML = '';

							for(var a=0;a<attrs.length; a++) {
								var attr=attrs[a];

								if(self[attr]) {
									img.setAttribute(attr, self[attr]);
								}
							}

							img.addEventListener('load', event=> {
								self.classList.add('loaded');
								self.setAttribute('style', `--width:${img.naturalWidth};--height:${img.naturalHeight}`);
								self.width  = img.naturalWidth;
								self.height = img.naturalHeight;
							});

							img.addEventListener('error', event=> {
								self.classList.remove('loaded');
							});

							self.appendChild(img);

							observer.unobserve(self);
						}
					}
				}, {
					rootMargin: '200px'
				});

				observer.observe(self);

				if(!this.getAttribute('style')){
					let styles=`--width:${this.width};--height:${this.height};`;

					this.setAttribute('style', styles);
				}
			}

			get src () {
				return this.getAttribute('src');
			}

			set src (src) {
				return this.setAttribute('src', src);
			}

			get width () {
				return this.getAttribute('width');
			}

			set width (width) {
				return this.setAttribute('width', width);
			}

			get height () {
				return this.getAttribute('height');
			}

			set height (height) {
				return this.setAttribute('height', height);
			}

			get alt () {
				return this.getAttribute('alt');
			}

			set alt (alt) {
				return this.setAttribute('alt', alt);
			}

			get srcset () {
				return this.getAttribute('srcset');
			}

			set srcset (srcset) {
				return this.setAttribute('srcset', srcset);
			}

			get sizes () {
				return this.getAttribute('sizes');
			}

			set sizes (sizes) {
				return this.setAttribute('sizes', sizes);
			}

			static get observedAttributes() {
				return ['width', 'height', 'src', 'alt', 'srcset', 'sizes'];
			}

			attributeChangedCallback(name, oldValue, newValue) {
				var image = this.querySelector('img');

				if(image) {
					image.oldValue 		= image.oldValue || {};
					image.oldValue[name] 	= oldValue;

					image.setAttribute(name, newValue);
				}
			}
		}

		customElements.define('lazy-image', LazyImage);
	}else {
		var observer = new IntersectionObserver(entries=> {
			entries.forEach(entry=> {
				if(entry.isIntersecting) {
					var img = document.createElement('img');
					var attrs = ['width', 'height', 'src', 'alt'];

					attrs.forEach(attr=> {
						img[attr] = entry.target[attr];
					});

					entry.target.appendChild(img);

					img.addEventListener('load', function() {
						entry.target.classList.add('loaded');
						entry.target.setWidth (img.naturalWidth);
						entry.target.setHeight(img.naturalHeight);
						entry.target.lazyStyle();
					});

					observer.unobserve(entry.target);
				}
			}, {
				rootMargin: '200px'
			})
		});

		function lazyImageFallback() {
			var self = this;
			this.src   	   = this.getAttribute('src')    || '';
			this.width 	   = this.getAttribute('width')  || 100;
			this.height    = this.getAttribute('height') || 100;
			this.alt 	   = this.getAttribute('alt') 	 || '';
			this.lazyStyle = function() {
				self.style.setProperty('--width', self.width);
			}
			this.setWidth = function(width) {
				self.width = width;
			}
			this.setHeight = function(height) {
				self.height = height;
				self.style.setProperty('--height', self.height);
			}

			if(!this.getAttribute('style')) {
				this.lazyStyle();
			}

			observer.observe(this);

			this.setAttribute('ready','');

		}
		_checker();

		function _checker() {
			var imgs = document.querySelectorAll('lazy-image:not([ready])');

			imgs.forEach(img=> {
				lazyImageFallback.call(img);
			});

			requestAnimationFrame(_checker);
		}
	}
})();
