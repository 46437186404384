(function() {
	let topo      = $('.topo');
	let btnSearch = $('[data-toggle="search"]');
	let btnMenu   = $('[data-toggle="menu"]');
	let search    = $('.search');
	let input 	  = $('.search .form-control');
	let menu      = $('.main__topo');

	btnMenu.click(function() {
		let conf = {
			target: menu,
			classe: 'main__topo--shown'
		}

		backdrop(conf);
	});

	btnSearch.click(function() {
		let conf= {
			target: search,
			classe : 'search--shown',
			callback: function() {
				input.focus();
			}
		};

		backdrop(conf);
	});

	function backdrop({target, classe, callback}){
		let bck = document.createElement('div');

		target.addClass(classe);

		$(bck)
			.addClass('backdrop')
			.click(function() {
				target.removeClass(classe);

				$('.backdrop').fadeOut(600, function () {
					$(this).remove();
				});
			});

		if(callback) callback();

		topo.append(bck);
	}
})();
