
(function() {
	const animatedElements = document.querySelectorAll('[data-animate]');
	const AnimateOnViewIntersect = new IntersectionObserver(entries=> {
		entries.forEach(entry=> {
			if(entry.isIntersecting) {
				let animationClasses = entry.target.dataset.animate;

				animationClasses = animationClasses.split(' ');

				animationClasses.forEach(classe => entry.target.classList.add(classe));

				entry.target.removeAttribute('data-animate');

				AnimateOnViewIntersect.unobserve(entry.target);
			}
		});
	}, {
		treshold: 0.25
	});

	animatedElements.forEach(el => AnimateOnViewIntersect.observe(el));
})();

