
if(!Array.prototype.forEach) {
	Array.prototype.forEach = function(callback, argumentos) {
		if(this === null) {
			throw new TypeError('this is null or not defined')
		}

		if(typeof callback !== 'function') {
			throw new TypeError(callback+' is not a function')
		}

		for(var i=0;i<this.length;i++) {
			var fn = callback.bind(this[i]);

			fn(this[i], argumentos);
		}
	}
}
