(function(){
	let masonryRow = $('.masonry-row');

	if(masonryRow.length > 0){
		let childs = masonryRow.children();

		masonryRow.masonry({
			itemSelector: '.masonry-item',
			columnWidth: '.masonry-sizer',
			percentPosition: true
		});

		$('.btn[data-toggle="masonry"]').click(function(){
			let btn 	= $(this)
			let rout 	= btn.data('content');

			$.ajax({
				url : rout,
				beforeSend: function(){
					btn.html('Carregando <i class="fas fa-spinner fa-spin"></i>');
				}
			}).done(function(request){
				let content = '';
				let template = `<div class="col-md-4 col-sm-6 mgb-30 masonry-item appended-after">
					<div class="noticia">
						<div class="thumbnail">
							<img src="{{thumbnail}}" class="img-fluid" alt="{{titulo}}"/>
						</div>
						<div class="noticia-content">
							<div class="data">
								<div class="dia-mes">
									<div class="dia">{{dia}}</div>
									<div class="mess">{{mes}}</div>
								</div>
								<div class="ano">{{ano}}</div>
							</div>
							<div class="noticia-body">
								<div class="autor-pub">
									<div class="autor"><em>por</em> <strong>{{autor}}</strong></div>
									<div class="publ"><em>Postado em:</em> {{publicado}}</div>
								</div>
								<div class="titulo">{{titulo}}</div>
								<div class="divider"></div>
								<div class="content">{{conteudo}}</div>
								<div class="text-center">
									<a href="{{href}}" class="btn btn-leia-mais">LEIA MAIS</a>
								</div>
							</div>
						</div>
					</div>
				</div>`;

				$(request.noticias).each(function(){
					let noticia = template;

					noticia = noticia.replace(/{{thumbnail}}/g, this.thumbnail);
					noticia = noticia.replace(/{{titulo}}/g, this.titulo);
					noticia = noticia.replace(/{{conteudo}}/g, this.conteudo);
					noticia = noticia.replace(/{{href}}/g, this.href);
					noticia = noticia.replace(/{{dia}}/g, this.dia);
					noticia = noticia.replace(/{{mes}}/g, this.mes);
					noticia = noticia.replace(/{{ano}}/g, this.ano);
					noticia = noticia.replace(/{{autor}}/g, this.autor);
					noticia = noticia.replace(/{{publicado}}/g, this.publicado);

					content += noticia;
				})

				let $content = $(content);

				masonryRow.append($content).masonry('appended', $content);

				if(!request.temMaisItens){
					btn.remove();
				}

				btn.html('CARREGAR MAIS');
			})
		});
	}
})();
