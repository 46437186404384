(()=> {
	const els = document.querySelectorAll('.md__file__form');

	els.forEach(el=> MdFile.call(el));

	function MdFile () {
		const $self  = this;
		const output = this.querySelector('.md__label__output');
		const input  = this.querySelector('.md__input__file');

		input.addEventListener('change', function() {
			$self.classList.add('focus');
			output.innerText = input.files[0].name;

			console.log(input.files)
		});
	}
})();
